import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { BleachBypassShader } from 'three/examples/jsm/shaders/BleachBypassShader.js';
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';

console.log("3D.js is connected");

class SceneManager {
    constructor() {
        // Camera constants
        this.CAMERA_FOV = 45;
        this.CAMERA_NEAR = 1;
        this.CAMERA_FAR = 2000;

        // Rotation speeds
        this.ROTATION_SPEEDS = {
            ananas: 0.009,
            stein: { x: 0.011, y: 0.01, z: 0.015 },
            sputnik: { x: 0.04 }
        };

        // Positions
        this.STEIN_POSITION = { x: 14, y: 0, z: 0 };
        this.BOX_SCALE = { x: 0.1, y: 0.1, z: 0.1 };
        this.BOX_POSITION = { x: 14, y: 0, z: 0 };
        this.SPUTNIK_POSITION = { x: 0, y: 0.5, z: 0 };

        // Scene elements
        this.container = null;
        this.camera = null;
        this.scene = null;
        this.renderer = null;
        this.controls = null;
        this.composer = null;
        this.afterimagePass = null;
        this.resourcesLoaded = false;
        this.envMap = null;
        
        // Style state
        this.currentStyleIndex = 0;
        this.styles = [
            'standard',    // 3D_0.js style
            'hdrenvironment', // 3D_1.js style
            'postprocessing'  // 3D_4.js style
        ];

        // Raycaster for click detection
        this.raycaster = new THREE.Raycaster();
        this.clickMouse = new THREE.Vector2();
        
        this.params = {
            enableAfterimage: true
        };
        
        this.mousePosition = { x: 0, y: 0 };
        this.windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
            halfX: window.innerWidth / 2,
            halfY: window.innerHeight / 2
        };

        this.groups = {
            all: null,
            ananas: null,
            stein: null,
            sputnik: null,
            box: null
        };

        this.textures = {
            pineapple: null,
            stein: null,
            sputnik: null
        };

        this.init();
        this.animate();
    }

updateDebug() {
    const debugElement = document.getElementById('debug');
    if (debugElement) {
        const debugInfo = `
            FPS: ${Math.round(1000 / this.frameTime)}
            Camera: (${this.camera.position.x.toFixed(2)}, ${this.camera.position.y.toFixed(2)}, ${this.camera.position.z.toFixed(2)})
            Scene children: ${this.scene.children.length}
            Canvas size: ${this.renderer.domElement.width}x${this.renderer.domElement.height}
        `;
        debugElement.textContent = debugInfo;
    }
}

initRenderer() {
    //console.log("Starting renderer initialization");
    
    if (!this.container) {
        console.error("Container is missing!");
        return;
    }
    
    this.renderer = new THREE.WebGLRenderer({ 
        alpha: true, // Change back to true for transparent background
        antialias: true,
        powerPreference: 'high-performance',
        stencil: false
    });
    
    // Set renderer properties
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.8;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    
    // Clear container first
    this.container.innerHTML = '';
    
    // Add canvas and set its styles
    const canvas = this.renderer.domElement;
    canvas.style.display = 'block';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    this.container.appendChild(canvas);
    
    // Add container styles but without the red background
    this.container.style.cssText = `
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background: transparent;
`;
}


async loadHDR() {
    const rgbeLoader = new RGBELoader(this.environmentLoadingManager);
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    try {
        const texture = await rgbeLoader.loadAsync('../content/360/360_7.2_mid.hdr');
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        texture.dispose();
        pmremGenerator.dispose();
        return envMap;
    } catch (error) {
        console.error('Error loading HDR:', error);
        throw error;
    }
}

    createSkyScene() {
        const scene = new THREE.Scene();
        
        // Create a large sphere for the sky
        const skyGeo = new THREE.SphereGeometry(100, 32, 32);
        
        // Create a gradient material
        const uniforms = {
            topColor: { value: new THREE.Color(0x0077ff) },
            bottomColor: { value: new THREE.Color(0xffffff) },
            offset: { value: 33 },
            exponent: { value: 0.6 }
        };

        const skyMat = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: `
                varying vec3 vWorldPosition;
                void main() {
                    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                    vWorldPosition = worldPosition.xyz;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform vec3 topColor;
                uniform vec3 bottomColor;
                uniform float offset;
                uniform float exponent;
                varying vec3 vWorldPosition;
                void main() {
                    float h = normalize(vWorldPosition + offset).y;
                    gl_FragColor = vec4(mix(bottomColor, topColor, max(pow(max(h, 0.0), exponent), 0.0)), 1.0);
                }
            `,
            side: THREE.BackSide
        });

        const sky = new THREE.Mesh(skyGeo, skyMat);
        scene.add(sky);

        return scene;
    }

    async init() {
        
        this.container = document.createElement('div');
        document.body.appendChild(this.container);
    
        this.loadingManager = this.setupLoadingManager();
        this.createLoadingScreen();

        this.scene = new THREE.Scene();
        
        this.camera = new THREE.PerspectiveCamera(
            this.CAMERA_FOV,
            this.windowSize.width / this.windowSize.height,
            this.CAMERA_NEAR,
            this.CAMERA_FAR
        );
        this.camera.position.set(0, 10, 33);
    
        this.initRenderer();
    
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);  // Match new value
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);  // Match new value
        directionalLight.position.set(5, 5, 5);
        
        this.scene.add(ambientLight);
        this.scene.add(directionalLight);
    
        try {
            const envMap = await this.loadHDR();
            if (envMap) {
                this.scene.environment = envMap;
                this.envMap = envMap;
            }
        } catch (error) {
            console.error('Environment setup failed:', error);
        }
    
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.enableZoom = true;
        this.controls.minDistance = 25;
        this.controls.maxDistance = 40;
    
        await this.setupGroups();
        await this.loadModels();
        this.setupEventListeners();
    }

    updateCursor() {
        this.raycaster.setFromCamera(this.clickMouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.groups.ananas.children, true);
    
        if (intersects.length > 0) {
            document.body.style.cursor = 'pointer';
        } else {
            document.body.style.cursor = 'default';
        }
    }

    createLoadingScreen() {
        const loadingScreen = document.createElement('div');
        loadingScreen.className = 'loading-screen';
        
        const loadingText = document.createElement('div');
        loadingText.className = 'loading-text';
        loadingText.textContent = 'Loading';
        
        const loadingPercentage = document.createElement('div');
        loadingPercentage.className = 'loading-percentage';
        loadingPercentage.textContent = '0%';
        
        loadingScreen.appendChild(loadingText);
        loadingScreen.appendChild(loadingPercentage);  // Add this line!
        document.body.appendChild(loadingScreen);
        
        this.loadingScreen = {
            element: loadingScreen,
            percentageElement: loadingPercentage  // Add this line!
        };
    }

    setupLoadingManager() {
        const loadingManager = new THREE.LoadingManager();
        const LOADING_TIMEOUT = 30000;
        
        // Track different loading stages
        const loadingStages = {
            textures: { weight: 0.3, progress: 0, completed: false },
            models: { weight: 0.4, progress: 0, completed: false },
            environment: { weight: 0.3, progress: 0, completed: false }
        };
    
        let lastDisplayedPercentage = 0;
    
        const calculateTotalProgress = () => {
            let total = 0;
            for (const stage in loadingStages) {
                total += loadingStages[stage].progress * loadingStages[stage].weight;
            }
            return Math.floor(total * 100);
        };
    
        const checkAllResourcesLoaded = () => {
            const allCompleted = Object.values(loadingStages).every(stage => stage.completed);
            if (allCompleted) {
                this.resourcesLoaded = true;
                //console.log('All resources loaded, starting render');
                
                // Remove loading screen after a small delay
                setTimeout(() => {
                    if (this.loadingScreen?.element) {
                        this.loadingScreen.element.remove();
                        this.loadingScreen = null;
                    }
                }, 200);
            }
        };
    
        const updateLoadingScreen = (percentage) => {
            // Round to nearest 10
            const roundedPercentage = Math.floor(percentage / 10) * 10;
            
            if (roundedPercentage > lastDisplayedPercentage) {
                lastDisplayedPercentage = roundedPercentage;
                if (this.loadingScreen?.percentageElement) {
                    //console.log(`Loading progress: ${roundedPercentage}%`);
                    this.loadingScreen.percentageElement.textContent = `${roundedPercentage}%`;
                }
            }
        };
    
        // Create separate loading managers for different resource types
        this.textureLoadingManager = new THREE.LoadingManager();
        this.modelLoadingManager = new THREE.LoadingManager();
        this.environmentLoadingManager = new THREE.LoadingManager();
    
        // Texture loading progress
        this.textureLoadingManager.onProgress = (url, loaded, total) => {
            loadingStages.textures.progress = loaded / total;
            updateLoadingScreen(calculateTotalProgress());
        };
    
        this.textureLoadingManager.onLoad = () => {
            loadingStages.textures.completed = true;
            loadingStages.textures.progress = 1;
            updateLoadingScreen(calculateTotalProgress());
            checkAllResourcesLoaded();
        };
    
        // Model loading progress
        this.modelLoadingManager.onProgress = (url, loaded, total) => {
            loadingStages.models.progress = loaded / total;
            updateLoadingScreen(calculateTotalProgress());
        };
    
        this.modelLoadingManager.onLoad = () => {
            loadingStages.models.completed = true;
            loadingStages.models.progress = 1;
            updateLoadingScreen(calculateTotalProgress());
            checkAllResourcesLoaded();
        };
    
        // Environment loading progress
        this.environmentLoadingManager.onProgress = (url, loaded, total) => {
            loadingStages.environment.progress = loaded / total;
            updateLoadingScreen(calculateTotalProgress());
        };
    
        this.environmentLoadingManager.onLoad = () => {
            loadingStages.environment.completed = true;
            loadingStages.environment.progress = 1;
            updateLoadingScreen(calculateTotalProgress());
            checkAllResourcesLoaded();
        };
    
        // Main loading manager handlers
        const loadingTimeout = setTimeout(() => {
            if (!this.resourcesLoaded) {
                console.error('Loading timed out');
                this.handleLoadingError();
            }
        }, LOADING_TIMEOUT);
    
        loadingManager.onLoad = () => {
            clearTimeout(loadingTimeout);
            // Note: We don't set resourcesLoaded here anymore
            // That's handled by checkAllResourcesLoaded()
            
            // Ensure we show 100% at the end
            if (this.loadingScreen?.percentageElement) {
                this.loadingScreen.percentageElement.textContent = '100%';
            }
        };
    
        loadingManager.onError = (url) => {
            console.error('Error loading:', url);
            if (this.loadingScreen?.percentageElement) {
                this.loadingScreen.percentageElement.textContent = 'Error loading resources';
            }
        };
    
        return loadingManager;
    }

    

    setupPostProcessing() {
        this.composer = new EffectComposer(this.renderer);
        
        const renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);

        const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
        this.composer.addPass(gammaCorrectionPass);

        this.afterimagePass = new AfterimagePass();
        this.afterimagePass.uniforms['damp'].value = 0.996;
        this.composer.addPass(this.afterimagePass);

        const filmPass = new FilmPass(0.35);
        this.composer.addPass(filmPass);

        const effectBleach = new ShaderPass(BleachBypassShader);
        effectBleach.uniforms['opacity'].value = 0.95;
        this.composer.addPass(effectBleach);

        const effectVignette = new ShaderPass(VignetteShader);
        effectVignette.uniforms['offset'].value = 1.6;
        effectVignette.uniforms['darkness'].value = 0.95;
        this.composer.addPass(effectVignette);

        const outputPass = new OutputPass();
        this.composer.addPass(outputPass);
    }

    async setupGroups() {
        // Create groups
        this.groups.all = new THREE.Group();
        this.groups.ananas = new THREE.Group();
        this.groups.stein = new THREE.Group();
        this.groups.sputnik = new THREE.Group();
    
        // Create helper cube that will be Sputnik's parent
        const helperGeometry = new THREE.BoxGeometry(0.001, 0.001, 0.001);
        const helperMaterial = new THREE.MeshBasicMaterial({ visible: false });
        this.helperCube = new THREE.Mesh(helperGeometry, helperMaterial);
        
        // Set up hierarchy
        this.groups.all.add(this.groups.ananas);
        this.groups.ananas.add(this.groups.stein);
        this.groups.ananas.add(this.helperCube);
        this.helperCube.add(this.groups.sputnik);

        // Position the groups
        this.groups.sputnik.position.set(0, this.SPUTNIK_POSITION.y, 5);
        this.scene.add(this.groups.all);
        
        this.groups.stein.position.set(
            this.STEIN_POSITION.x,
            this.STEIN_POSITION.y,
            this.STEIN_POSITION.z
        );

        this.helperCube.position.set(
            this.STEIN_POSITION.x,
            this.STEIN_POSITION.y,
            this.STEIN_POSITION.z
        );
    
        // Add to scene
        this.scene.add(this.groups.all);
    }

    async loadModels() {
        const objLoader = new OBJLoader(this.loadingManager);
        
        // Load textures first
        this.textures.pineapple = await this.loadTexture('/3d/mat/Pineapple.png');
        this.textures.stein = await this.loadTexture('/3d/mat/STEIN_LOWRES.jpg');
        this.textures.sputnik = await this.loadTexture('/3d/mat/Sputnik_001_Sputnik_BaseColor.png');
        
        return Promise.all([
            this.loadModel(objLoader, this.textures.pineapple, '/3d/mesh/Pineapple.obj', 
                this.groups.ananas, { x: 0, y: 0, z: 0 }),
            this.loadModel(objLoader, this.textures.stein, '/3d/mesh/STEIN_LOWRES.obj', 
                this.groups.stein),
            this.loadModel(objLoader, this.textures.sputnik, '/3d/mesh/Sputnik.obj', 
                this.groups.sputnik, { x: 0, y: 5, z: 0 }) // Ensure position is set correctly
        ]).catch(console.error);
    }
    
    async loadModel(loader, texture, modelPath, group, position = { x: 0, y: 0, z: 0 }, scale = { x: 1, y: 1, z: 1 }, rotation = { x: 0, y: 0, z: 0 }) {
        try {
           // console.log(`Starting to load model: ${modelPath}`);
            const modelLoader = new OBJLoader(this.modelLoadingManager);
            const object = await new Promise((resolve, reject) => {
                modelLoader.load(
                    modelPath,
                    (object) => {
                        console.log(`Successfully loaded model: ${modelPath}`);
                        try {
                            object.traverse((child) => {
                                if (child instanceof THREE.Mesh) {
                                   // console.log(`Creating material for mesh in ${modelPath}`);
                                    const material = new THREE.MeshPhysicalMaterial({
                                        map: texture,
                                        envMap: this.envMap,
                                        envMapIntensity: 1.2,
                                        metalness: modelPath.includes('Sputnik') ? 0.8 : 0.3,
                                        roughness: modelPath.includes('Sputnik') ? 0.5 : 0.7,
                                        color: 0xffffff,
                                        normalScale: new THREE.Vector2(1, 1),
                                        clearcoat: 0.1,
                                        clearcoatRoughness: 0.7
                                    });
                                    child.material = material;
                                }
                            });
    
                            object.position.set(position.x, position.y, position.z);
                            object.scale.set(scale.x, scale.y, scale.z);
                            object.rotation.set(rotation.x, rotation.y, rotation.z);
                            group.add(object);
                            resolve(object);
                        } catch (error) {
                            console.error(`Error processing model ${modelPath}:`, error);
                            reject(error);
                        }
                    },
                    (progress) => {
                        console.log(`Loading progress for ${modelPath}:`, (progress.loaded / progress.total * 100) + '%');
                    },
                    (error) => {
                        console.error(`Error loading model ${modelPath}:`, error);
                        reject(error);
                    }
                );
            });
            return object;
        } catch (error) {
            console.error(`Failed to load model ${modelPath}:`, error);
            throw error;
        }
    }

    async loadTexture(path) {
       // console.log(`Starting to load texture: ${path}`);
        const textureLoader = new THREE.TextureLoader(this.textureLoadingManager);
        try {
            const texture = await new Promise((resolve, reject) => {
                textureLoader.load(
                    path,
                    (texture) => {
                       // console.log(`Successfully loaded texture: ${path}`);
                        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
                        resolve(texture);
                    },
                    (progress) => {
                        console.log(`Loading progress for ${path}:`, (progress.loaded / progress.total * 100) + '%');
                    },
                    (error) => {
                        console.error(`Error loading texture ${path}:`, error);
                        reject(error);
                    }
                );
            });
            return texture;
        } catch (error) {
            console.error(`Failed to load texture ${path}:`, error);
            throw error;
        }
    }

    async switchToStyle(styleName) {
       // console.log(`Switching to style: ${styleName}`);
        
        // First remove any existing lights
        this.scene.children.forEach(child => {
            if (child instanceof THREE.Light) {
                this.scene.remove(child);
            }
        });
    
        switch(styleName) {
            case 'standard':
                this.groups.all.traverse((child) => {
                    if (child instanceof THREE.Mesh && child.material) {
                        child.material.dispose();
                    }
                });
                
                // Clear composer if it exists
                if (this.composer) {
                    this.composer.dispose();
                    this.composer = null;
                }
                // Reload textures
                this.textures.pineapple = await this.loadTexture('/3d/mat/Pineapple.png');
                this.textures.stein = await this.loadTexture('/3d/mat/STEIN_LOWRES.jpg');
                this.textures.sputnik = await this.loadTexture('/3d/mat/Sputnik_001_Sputnik_BaseColor.png');
            


                // Reset environment
                this.scene.environment = null;
                this.scene.background = null;
            
                
                // Add standard style lights
                const standardAmbient = new THREE.AmbientLight(0xffffff, 0.3);
                const standardDirectional = new THREE.DirectionalLight(0xffffff, 0.5);
                standardDirectional.position.set(5, 5, 5);
                this.scene.add(standardAmbient, standardDirectional);
    
                // Set up environment map for proper material reflection
                try {
                    this.envMap = await this.loadHDR();
                    this.scene.environment = this.envMap;
                } catch (error) {
                    console.error('Failed to load HDR:', error);
                }
                
                await this.updateMaterials('standard');
                break;
    
            case 'hdrenvironment':
                this.groups.all.traverse((child) => {
                    if (child instanceof THREE.Mesh && child.material) {
                        child.material.dispose();
                    }
                });
                
                // Clear composer if it exists
                if (this.composer) {
                    this.composer.dispose();
                    this.composer = null;
                }
            
                // Reset environment
                this.scene.environment = null;
                this.scene.background = null;
            
                // Add metallic style lights
                const metallicAmbient = new THREE.AmbientLight(0xffffff, 0.5);
                const metallicDirectional = new THREE.DirectionalLight(0xffffff, 3);
                metallicDirectional.position.set(5, 5, 5);
                this.scene.add(metallicAmbient, metallicDirectional);
    
                // Load and set up environment map
                const textureLoader = new THREE.TextureLoader(this.loadingManager);
                textureLoader.load('/content/360/360Forest.webp', (texture) => {
                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    texture.encoding = THREE.sRGBEncoding;
                    texture.rotation = Math.PI * 2;
                    texture.needsUpdate = true;
                    this.scene.background = texture;
                    this.scene.environment = texture;
                });
    
                // Update materials to match 3D_1.js exactly
                this.groups.all.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material.dispose();
                        child.material = new THREE.MeshStandardMaterial({
                            color: 0xffffff,
                            metalness: 1.0,
                            roughness: 0.0,
                            envMapIntensity: 1.0
                        });
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });
    
                // Update renderer settings
                this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
                this.renderer.toneMappingExposure = 0.8;
                this.renderer.shadowMap.enabled = true;
                this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
                break;
    
                case 'postprocessing':
                    this.groups.all.traverse((child) => {
                        if (child instanceof THREE.Mesh && child.material) {
                            child.material.dispose();
                        }
                    });
                    
                    if (this.composer) {
                        this.composer.dispose();
                        this.composer = null;
                    }
                
                    this.scene.environment = null;
                    this.scene.background = null;
                
                    // Add standard lighting with unique variable names
                    const postAmbient = new THREE.AmbientLight(0xffffff, 0.3);
                    const postDirectional = new THREE.DirectionalLight(0xffffff, 0.5);
                    postDirectional.position.set(5, 5, 5);
                    this.scene.add(postAmbient, postDirectional);
                
                    try {
                        this.envMap = await this.loadHDR();
                        this.scene.environment = this.envMap;
                    } catch (error) {
                        console.error('Failed to load HDR:', error);
                    }
                
                    this.groups.all.traverse((child) => {
                        if (child instanceof THREE.Mesh) {
                            let texture = null;
                            let metalness = 0.3;
                            let roughness = 0.9;
                
                            let parent = child.parent;
                            while (parent) {
                                if (parent === this.groups.ananas) {
                                    texture = this.textures.pineapple;
                                    break;
                                } else if (parent === this.groups.stein) {
                                    texture = this.textures.stein;
                                    break;
                                } else if (parent === this.groups.sputnik) {
                                    texture = this.textures.sputnik;
                                    metalness = 0.8;
                                    roughness = 0.5;
                                    break;
                                }
                                parent = parent.parent;
                            }
                
                            child.material.dispose();
                            child.material = new THREE.MeshPhysicalMaterial({
                                map: texture,
                                envMap: this.envMap,
                                envMapIntensity: 0.8,
                                metalness: metalness,
                                roughness: roughness,
                                color: 0xffffff
                            });
                        }
                    });
                
                    this.setupPostProcessing();
                    break;
                }
            }

    async updateMaterials(materialType) {
        this.groups.all.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                let newMaterial;
                let texture = null;
                let metalness = 0.3;
                let roughness = 0.9; 
    
                // Check ancestors to determine which group the mesh belongs to
                let parent = child.parent;
                while (parent) {
                    if (parent === this.groups.ananas) {
                        texture = this.textures.pineapple;
                        break;
                    } else if (parent === this.groups.stein) {
                        texture = this.textures.stein;
                        break;
                    } else if (parent === this.groups.sputnik) {
                        texture = this.textures.sputnik;
                        metalness = 0.8;
                        roughness = 0.5;
                        break;
                    }
                    parent = parent.parent;
                }
    
                switch(materialType) {
                    case 'standard':
                        newMaterial = new THREE.MeshPhysicalMaterial({
                            map: texture,
                            envMap: this.envMap,
                            envMapIntensity: 1.2,    // Increase from 0.5 for more environment light reflection
                            metalness: metalness,
                            roughness: 0.7,          // Decrease from 0.9 for more shine
                            color: 0xffffff,
                            clearcoat: 0.1,          // Increase from 0.1 for more surface reflection
                            clearcoatRoughness: 0.7,  // Decrease from 0.3 for cleaner reflections
                            normalScale: new THREE.Vector2(1, 1)
                        });
                        break;
    
                    case 'metallic':
                        newMaterial = new THREE.MeshStandardMaterial({
                            color: 0xffffff,
                            metalness: 1.0,
                            roughness: 0.0,
                            envMapIntensity: 1.0
                        });
                        break;
    
                    case 'physical':
                        newMaterial = new THREE.MeshPhysicalMaterial({
                            color: 0xffffff,
                            metalness: metalness,
                            roughness: roughness,
                            envMapIntensity: 0.8,
                            map: texture
                        });
                        break;
                }
    
                if (child.material) {
                    child.material.dispose();
                }
                child.material = newMaterial;
            }
        });
    }

    setupEventListeners() {
        this.boundOnWindowResize = this.onWindowResize.bind(this);
        this.boundOnDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.boundOnClick = this.onClick.bind(this);
        
        window.addEventListener('resize', this.boundOnWindowResize);
        document.addEventListener('mousemove', this.boundOnDocumentMouseMove);
        window.addEventListener('click', this.boundOnClick);
    }

    onClick(event) {
        this.clickMouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.clickMouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        this.raycaster.setFromCamera(this.clickMouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.groups.ananas.children, true);

        if (intersects.length > 0) {
          //  console.log('pineapple clicked');
            
            // Cycle to next style
            this.currentStyleIndex = (this.currentStyleIndex + 1) % this.styles.length;
            this.switchToStyle(this.styles[this.currentStyleIndex]);
        }
    }

    onWindowResize() {
        this.windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
            halfX: window.innerWidth / 2,
            halfY: window.innerHeight / 2
        };

        this.camera.aspect = this.windowSize.width / this.windowSize.height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.windowSize.width, this.windowSize.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        if (this.composer) {
            this.composer.setSize(this.windowSize.width, this.windowSize.height);
        }
    }

    onDocumentMouseMove(event) {
        this.mousePosition.x = (event.clientX - this.windowSize.halfX) / 2;
        this.mousePosition.y = (event.clientY - this.windowSize.halfY) / 2;
    
        this.clickMouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.clickMouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
        this.updateCursor();
    }

    animate() {

        requestAnimationFrame(this.animate.bind(this));
        if (!this.resourcesLoaded) {
            console.log("Resources not loaded yet, skipping render");
            return;
        }
        this.render();
        this.controls.update();
    }
    
    render() {
        if (!this.camera || !this.scene || !this.groups.all || !this.resourcesLoaded) {
            console.log("Skipping render, missing:", {
                camera: !this.camera,
                scene: !this.scene,
                groups: !this.groups.all,
                resourcesLoaded: !this.resourcesLoaded
            });
            return;
        }
        
        
        this.groups.ananas.rotation.y += this.ROTATION_SPEEDS.ananas;
        this.groups.stein.rotation.x += this.ROTATION_SPEEDS.stein.x;
        this.groups.stein.rotation.y += this.ROTATION_SPEEDS.stein.y;
        this.groups.stein.rotation.z += this.ROTATION_SPEEDS.stein.z;
        
        if (this.helperCube) {
            this.helperCube.rotation.x += 0.02;
        }
    
        if (Math.abs(this.mousePosition.y) > 0.1) {
            this.camera.position.y += (-this.mousePosition.y - this.camera.position.y) * 0.001;
            this.camera.lookAt(this.scene.position);
        }
    
        if (this.composer && this.styles[this.currentStyleIndex] === 'postprocessing') {
            this.afterimagePass.enabled = this.params.enableAfterimage;
            this.composer.render();
        } else {
            this.renderer.render(this.scene, this.camera);
        }
    }

    cleanup() {
        window.removeEventListener('resize', this.boundOnWindowResize);
        document.removeEventListener('mousemove', this.boundOnDocumentMouseMove);
        window.removeEventListener('click', this.boundOnClick);
        
        if (this.composer) {
            this.composer.dispose();
        }
        
        this.renderer.dispose();
        
        // Dispose of materials and geometries
        this.scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                object.geometry.dispose();
                object.material.dispose();
            }
        });
    }
}

const sceneManager = new SceneManager();

// Export if needed
export default sceneManager;